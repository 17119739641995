/**
 * Class element
 * Map component
 * Display the map only in desktop and tablet
 */

/* Import */
import React, { Component } from "react"
//import Mapgoogle from "./mapgoogle"
import IframeMapGoogle from "./iframemapgoogle"

/* Declaration class */
class Map extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  render () {
    const isMobile = this.state.width <= 568


    return (
      <div>
        {!isMobile && <IframeMapGoogle />}
      </div>
    )
  }
}

/* Export */
export default Map
