/**
 * Const with StaticQuery and param element
 * Contact infos component
 * Display contact information
 */
/* Import section */
import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { IconContext } from "react-icons"
import { BsPhone, BsEnvelope, BsPinMapFill } from "react-icons/bs"
import "../styles/components/contactinfos.scss"

/* Declaration function with StaticQuery */
const Contactinfos = ( { language } ) => (
  <StaticQuery
    query={graphql`
      query {
        wpPage(slug: {eq: "options-website"}) {
          acfOptionsWebsite {
            businessAdress1
            businessAdress1Fr
            businessAdress2
            businessAdress2Fr
            businessAdress3
            businessEmail
            businessPhone
          }
        }
      }
    `}
    render={data => (
      <ul className="list-infos-contact">
        <li>
          <span className="span-icon">
            <IconContext.Provider value={{ className: "icon-phone icon-svg" }}>
              <BsPhone />
            </IconContext.Provider>
            <span><a href={`tel:+${data.wpPage.acfOptionsWebsite.businessPhone}`} target="_blank" rel="noreferrer">{data.wpPage.acfOptionsWebsite.businessPhone}</a></span>
          </span>
        </li>
        <li>
          <span className="span-icon">
            <IconContext.Provider value={{ className: "icon-enveloppe icon-svg" }}>
              <BsEnvelope />
            </IconContext.Provider>
              <span><a href={`mailto:${data.wpPage.acfOptionsWebsite.businessEmail}`} target="_blank" rel="noreferrer">{data.wpPage.acfOptionsWebsite.businessEmail}</a></span>
          </span>
        </li>

        <li>
          <span className="span-icon">
            <IconContext.Provider value={{ className: "icon-clock icon-svg" }}>
              <BsPinMapFill />
            </IconContext.Provider>
              <span><a href="https://g.page/myca-qc?share" target="_blank" rel="noreferrer">{language === "en" ? data.wpPage.acfOptionsWebsite.businessAdress1:data.wpPage.acfOptionsWebsite.businessAdress1Fr} <br/>
              {language === "en" ? data.wpPage.acfOptionsWebsite.businessAdress2:data.wpPage.acfOptionsWebsite.businessAdress2Fr}<br/>
              {data.wpPage.acfOptionsWebsite.businessAdress3}</a></span>
          </span>
        </li>
      </ul>
    )}
  />
)

/* Export function */
export default Contactinfos
