/**
 * Const without params element
 * Iframe Map Google component
 * Display map of Myca from Google Map Iframe
 */

/* Import */
import React from "react"


const IframeMapGoogle = () => {
  return (
    <iframe
      class="google-map"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10925.575544881247!2d-71.3147773!3d46.7965465!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6a876e8f48b7259!2sMyca%20Health%20Inc.!5e0!3m2!1sen!2sca!4v1638806866335!5m2!1sen!2sca" width="400" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  )
}

export default IframeMapGoogle