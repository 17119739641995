/**
 * Const with params element
 * Title decorative component
 * Display an H1 or H2 element with a decorative bar under
 */
/* Import section */
import PropTypes from "prop-types"
import React from "react"
import "../styles/components/titledecorative.scss"

/* Declaration function */
const Titledecorative = ({ titleType, title, colorBar }) => {
  return (
    <div className="title-decorative">
      {titleType === "h2" ? (
        <h2 dangerouslySetInnerHTML={{__html:title}} />
      ) : (
        <h1 dangerouslySetInnerHTML={{__html:title}} />
      )}
      <span className={`decorative-bar ${colorBar}`}></span>
    </div>
  )
}

/* Function propTypes declaration */
Titledecorative.propTypes = {
  titleType: PropTypes.string,
  title: PropTypes.string,
  colorBar: PropTypes.string,
}

Titledecorative.defaultProps = {
  titleType: "h2",
  title: "Title",
  colorBar: ""
}

/* Export function */
export default Titledecorative
