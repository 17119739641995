/**
 * Contact page
 */
/* Import section */
import * as React from "react"
import { graphql } from "gatsby"
import { I18nextContext} from "gatsby-plugin-react-i18next"
import Contactinfos from "../components/contactinfos"
import Formcontact from "../components/formcontact"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Map from "../components/map"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/pages/contact.scss"

/* Declaration */
const ContactPage = ({data}) => {
  //Const data
  const dataPage = data.wpPage
  const context = React.useContext(I18nextContext)
  var cLangSlug = context.language
  var cLang = (cLangSlug === "en") ? "En" : "Fr"

  //Variables of the query
  var cAcf = "acfPageContact"+cLang
  var cTitleH1 = "titleH1Contact"+cLang
  var cTitleForm = "titleForm"+cLang
  var cTextForm = "textForm"+cLang
  var cTitleCoord = "titleCoord"+cLang

  const dataAcf = dataPage[cAcf]
  const titleH1 = dataAcf[cTitleH1]
  const titleForm = dataAcf[cTitleForm]
  const textForm = dataAcf[cTextForm]
  const titleCoord = dataAcf[cTitleCoord]

  return(
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc} />

      <section className="section-hero-contact section-herotype2">
        <Herotype2 h1title={titleH1} />
      </section>

      <section className="section-contact flex-section-two">
        <div className="content-form flex-content-two">
          <div className="wrap-form flex-wrap-two">
            <Titledecorative titleType="h2" title={titleForm} colorBar="light" />
            <div dangerouslySetInnerHTML={{__html:textForm}} />
            <Formcontact />
          </div>
        </div>

        <div className="content-coord flex-content-two bluelight-box">
          <div className="wrap-coord flex-wrap-two">
            <Titledecorative titleType="h2" title={titleCoord} colorBar="marin" />
            <Contactinfos language={cLangSlug} />
          </div>
          <Map />
        </div>
      </section>

    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(slug: {regex: "/contact/"}, language: {slug: {eq: $language}}) {
      acfPageContactEn {
        titleH1ContactEn
        titleFormEn
        textFormEn
        titleCoordEn
      }
      acfPageContactFr {
        titleH1ContactFr
        titleFormFr
        textFormFr
        titleCoordFr
      }
      content
      seo {
        metaDesc
        title
      }
    }
  }
`

export default ContactPage
