/**
 * Const without params element
 * Form contact component
 * Display form contact and send via Netlify
 */

/* Import */
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { navigate } from "gatsby-link"
import "../styles/common/netlifyform.scss"

/* Declaration */
const Formcontact = () => {
  const {t} = useTranslation()
  useStaticQuery(graphql`
    query {
      locales: allLocale(filter: {ns: {in: ["form", "common"]}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `)

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <form
      name="myca-contact"
      method="post"
      action="/thank-you"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className="netlify-form"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="myca-contact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <fieldset className="form-columns-2">
        <div className="ny-form-field">
          <label>{t('name')}<span className="ny-form-required">*</span></label>
          <input className="ny-input" type="text" name="name" onChange={handleChange} required/>
        </div>
        <div className="ny-form-field">
          <label>{t('email')}<span className="ny-form-required">*</span></label>
          <input className="ny-input" type="email" name="email" onChange={handleChange} required/>
        </div>
      </fieldset>

      <fieldset className="form-columns-1">
        <div className="ny-form-field">
          <label>{t('subject')} <span className="ny-form-required">*</span></label>
          <input className="ny-input" type="text" name="subject" onChange={handleChange} required/>
        </div>
      </fieldset>

      <fieldset className="form-columns-1">
        <div className="ny-form-field">
          <label>{t('message')} <span className="ny-form-required">*</span></label>
          <textarea className="ny-input" type="text" name="message" onChange={handleChange} required/>
        </div>
      </fieldset>

      <div className="ny-submit">
        <input className="ny-input" type="submit" name="submit" value={t('submit')} />
      </div>
    </form>
  )
}

/* Export */
export default Formcontact

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

